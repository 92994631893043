import { TextField, IconButton } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Search from '@material-ui/icons/Search'
import { useHistory } from 'react-router-dom';

function SearchBar(props) {
    const history = useHistory();

    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const query = searchParams.get('query');
        if (query != "null") {
          setSearchQuery(query);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        let url = '/search?query=' + searchQuery
        history.push(url)
        if(props.setSearchQuery) {
            props.setSearchQuery(searchQuery)
        }
    }

  return (
    <form className='SearchBar' style={{paddingTop:'30px'}} onSubmit={handleSubmit}>
        <TextField
        id="search-bar"
        className="text"
        variant="outlined"
        placeholder={'Search...'}
        value={searchQuery}
        size="small"
        style={{float:'right', paddingRight:'15px', width:'30%', maxWidth:'400px'}}
        onChange={e=>setSearchQuery(e.target.value)}
        inputProps={{maxLength:200}}
        autoComplete='off'
        InputProps={{
            endAdornment: (
              <IconButton type="submit" aria-label="search" >
          <Search style={{ fill: "blue" }} />
          </IconButton>
            ),
          }}
        />
    </form>
  );
}
export default SearchBar;
