import {React, useState, useEffect} from 'react';
import ReactGA from 'react-ga';
import { Trans, useTranslation } from "react-i18next";
import "../styles/HeaderFooter-style.css";

import { useAppInsightsContext} from "@microsoft/applicationinsights-react-js";
import i18n from '../i18n';

const Footer = ({settings}) => { 
    const date = new Date();

    const appInsights = useAppInsightsContext();

    const [showUpdatedFooter, setShowUpdatedFooter] = useState(false);

    useEffect(() => {
        const checkLanguage = () => {
            const localStorageLang = localStorage.getItem("i18nextLng");
            const langEnglish = i18n.language === "en-US" || i18n.language === "en" || localStorageLang === "en-US" || !localStorageLang;
            setShowUpdatedFooter(langEnglish);
        }

        // check lang on first load hopefully
        checkLanguage();

        i18n.on("languageChanged", checkLanguage);
    }, [i18n]);

    function pressVALink() {
        appInsights.trackEvent({name: 'Footer Virtual Assistant Link Pressed'})
    }

    function pressAccessibilityLink() {
        appInsights.trackEvent({name: 'Footer Accessibility Link Pressed'})
    }

    function pressPrivacyLink() {
        appInsights.trackEvent({name: 'Footer Privacy Link Pressed'})
    }

    function pressConditionsLink() {
        appInsights.trackEvent({name: 'Footer Conditions Link Pressed'})
    }

    return (
        <footer>
            <div className='footerContainer'>
                <div className='footerSection'>
                    <a href="https://www.ca.gov/" target='_blank' rel="noopener noreferrer" style={{ margin: "0", display: "inline-block", cursor: "pointer" }} 
                    tabIndex="0">
                        <img
                            className='footerCAGovImg'
                            alt={"CA Gov Logo"}
                            width='150px'
                            src="/imgs/new-cagov-logo.svg"
                            target="_blank"
                        />
                    </a>
                    {/*Temporary Fix: If English, display english Cair contact in footer using different translations. Later, use these translations for all languages */}
                    {(showUpdatedFooter) ? (
                    <div className='leftSectionContainer'>
                        <span><Trans i18nKey="landingPage.calGovSite">Official California State Goverment Website</Trans></span>
                        <br></br>
                        <p style={{fontSize: "16px"}}>
                            <Trans i18nKey="footer.needHelp">
                                Need help or have questions?
                            </Trans>
                        </p>
                        <ReactGA.OutboundLink
                            eventLabel="vat_url_link"
                            to={settings.VATLink}
                            target="_blank"
                            className='vaLinkText'
                            onClick={pressVALink}
                        >
                            {/* <Trans i18nKey="footer.accessibility">Submit a request to the Virtual Assistant</Trans> */}
                            <Trans i18nKey="landingPage.submitVARequest">Submit a request to the Virtual Assistant</Trans>
                        </ReactGA.OutboundLink>
                        <br/>
                        <p style={{fontSize: "16px"}}>
                            <Trans i18nKey="footer.cairContact">
                                Need help or have questions? Call the CDPH Call Center at 833-422-4255 Mon-Fri 8AM-5PM (excludes holidays)
                            </Trans>
                        </p>
                            
                    </div>
                    ) : (
                        <div className='leftSectionContainer'>
                        <span><Trans i18nKey="landingPage.calGovSite">Official California State Goverment Website</Trans></span>
                        <br></br>
                        <p style={{fontSize: "16px"}}>
                            <Trans i18nKey="landingPage.contactBody1">
                                Need help or have questions? Call the CDPH Call Center at 833-422-4255 Mon-Fri 8AM-5PM (excludes holidays)
                            </Trans>
                        </p>
                        <ReactGA.OutboundLink
                            eventLabel="vat_url_link"
                            to={settings.VATLink}
                            target="_blank"
                            className='vaLinkText'
                            onClick={pressVALink}
                        >
                            {/* <Trans i18nKey="footer.accessibility">Submit a request to the Virtual Assistant</Trans> */}
                            <Trans i18nKey="landingPage.submitVARequest">Submit a request to the Virtual Assistant</Trans>
                        </ReactGA.OutboundLink>
                            
                    </div>
                    )}
                </div>
                <div className='footerSection'>
                    <a href="https://www.cdph.ca.gov/" target='_blank' rel="noopener noreferrer" style={{ margin: "0", display: "inline-block", cursor: "pointer" }}
                    tabIndex="0">
                        <img
                            alt={"CDPH Logo"}
                            src="/imgs/new-cdph-logo.png"
                            className='footerCDPHImg'
                        />
                    </a>
                    <div className='rightSectionContainer'>
                        <span>
                            <Trans i18nKey="landingPage.privacy">
                                {/* Please review the */}
                                <a
                                    href="https://www.cdph.ca.gov/Pages/privacy-policy.aspx"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={pressPrivacyLink}
                                    style={{
                                        color: "#0d6efd",
                                        margin: "0",
                                        textDecoration: "underline",
                                    }}
                                    >
                                    Privacy policy,
                                </a>
                                    <a
                                    href="https://www.cdph.ca.gov/Pages/Accessibility.aspx"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={pressAccessibilityLink}
                                    style={{
                                        color: "#0d6efd",
                                        margin: "0",
                                        textDecoration: "underline",
                                    }}
                                    >
                                    Accessibility,
                                </a>
                                <a
                                    href="https://www.cdph.ca.gov/Pages/Conditions-of-Use.aspx"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={pressConditionsLink}
                                    style={{
                                        color: "#0d6efd",
                                        margin: "0",
                                        textDecoration: "underline",
                                    }}
                                    >
                                    Conditions of Use.
                                </a>
                            </Trans>
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;