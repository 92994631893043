import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import QandA from '../styles/QandA.module.css';

// import * as fs from 'fs';
/* eslint-disable no-unused-vars */
/* eslint-disable no-irregular-whitespace */

export function QAContent(props) {
  const {
    title,
    para,
    generalText,
    covid19Text,
    fluText,
    bottomText,
    covidQA,
    generalQA,
    fluQA,
    learnMore,
    mpoxLink,
    covidLink,
    fluLink,
    generalButton,
    covidButton,
    fluButton,
    getAnswers,
  } = props;
  const [isMobile, setIsMobile] = React.useState('');
  const [maxHeight, setMaxHeight] = useState(0);

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    const calculateMaxHeight = () => {
      const boxes = document.querySelectorAll('.QandA.cardContainer');
      let maxHeight = 0;
      boxes.forEach((box) => {
        const height = box.clientHeight;
        if (height > maxHeight) {
          maxHeight = height;
        }
      });
      setMaxHeight(maxHeight);
    };

    // Call the function initially
    calculateMaxHeight();

    // Add event listener for window resize
    window.addEventListener('resize', calculateMaxHeight);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', calculateMaxHeight);
    };
  }, []);

  const getMobileOperatingSystem = () => {
    const { userAgent } = window.navigator;
    const { platform } = window.navigator;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];

    let OS = 'not mobile';

    if (userAgent.indexOf('Mac') !== -1) OS = 'A';
    if (userAgent.indexOf('Android') !== -1) OS = 'G';
    if (macosPlatforms.indexOf(platform) !== -1) {
      OS = 'not mobile';
    }

    return OS;
  };

  // choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 900) {
      setIsMobile('mobile');
    } else {
      setIsMobile('not mobile');
    }
  };

  /// //////////////////////////////////////////////////////////////////////////
  /// //SAVING LAST LOCATION OF VAXXQA CARDS///////////////////////////////////
  const handlePosition = () => {
    const scrollPosition = window.scrollY;
    localStorage.setItem('scrollPosition', scrollPosition.toString());
  };

  useEffect(() => {
    const restoreScroll = () => {
      const savedPosition = localStorage.getItem('scrollPosition');
      if (savedPosition !== null) {
        setTimeout(() => {
          window.scrollTo(0, parseInt(savedPosition, 10));
          localStorage.setItem('scrollPosition', '0');
        }, 1000);
      }
    };

    restoreScroll();

    window.addEventListener('popstate', restoreScroll);

    return () => {
      window.removeEventListener('popstate', restoreScroll);
    };
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);

    if (getMobileOperatingSystem() === 'A') {
      setIsMobile('mobile');
    } else if (getMobileOperatingSystem() === 'G') {
      setIsMobile('mobile');
    } else if (getMobileOperatingSystem() === 'not mobile') {
      setIsMobile('not mobile');
    }
  }, []);

  const { i18n } = useTranslation();

  function pressGeneralQAButton() {
    appInsights.trackEvent({ name: 'General Q&A Pressed' });
  }

  function pressCovidQAButton() {
    appInsights.trackEvent({ name: 'Covid Q&A Pressed' });
  }

  function pressHealthcareProviderQAButton() {
    appInsights.trackEvent({ name: 'Healthcare Providers Q&A Pressed' });
  }

  return (
    <>
      {isMobile === 'not mobile' && (
        <div className={QandA.root}>
          <div className={QandA.container}>
            <div className={QandA.titlesContainer}>
              {/* <div className={QandA.title} dangerouslySetInnerHTML={{ __html: title }} />
                            <div className={QandA.subtitle} dangerouslySetInnerHTML={{ __html: para }} /> */}
              <div className={QandA.title}>
                <Trans i18nKey="generalFaq.title">DVR Q&A</Trans>
              </div>
            </div>
            <div
              className={QandA.bodyContainer}
              role="button"
              tabIndex={0}
              onClick={handlePosition}
              onKeyDown={(e) => {
                if (e.key === ' ' || e.key === 'Enter') {
                  e.preventDefault();
                  handlePosition();
                }
              }}
              aria-label=""
            >
              <div className={QandA.columnContainer}>
                <div className={QandA.vaccineTitles}>
                  <Link to="/faq?tab=0" onClick={pressGeneralQAButton}>
                    <a href="/faq?tab=0" role="button" tabIndex={-1}>
                      <Trans i18nKey="generalFaq.general">General</Trans>
                      <img
                        className={QandA.arrowRight}
                        src="/imgs/internal-arrow.svg"
                        alt=""
                      />
                    </a>
                  </Link>
                </div>

                <Link to="/faq?tab=0" onClick={pressGeneralQAButton}>
                  <div
                    className={QandA.cardContainer}
                    style={{ borderTop: '10px solid #F06724' }}
                  >
                    <button
                      className={QandA.cardTitleButton}
                      type="button"
                      style={{
                        backgroundColor: '#F06724',
                        fontSize:
                          props.displayLocale === 'es_US' ? '14px' : '16px',
                      }}
                    >
                      <Trans i18nKey="generalFaq.general">General</Trans>
                    </button>
                    <div className={QandA.cardText}>
                      {/* <div dangerouslySetInnerHTML={{ __html: generalText }} /> */}
                      <p>
                        <Trans i18nKey="generalFaq.generalQuestion1">
                          What is a Digital Vaccine Record?
                        </Trans>
                      </p>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <a className={QandA.learnMoreText} href="/faq?tab=0">
                          <Trans i18nKey="landingPage.learnMore">
                            Learn more
                          </Trans>
                          <img
                            className={QandA.arrowRightSmall}
                            src="/imgs/internal-arrow.svg"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className={QandA.columnContainer}>
                <div className={QandA.vaccineTitles}>
                  <Link to="/faq?tab=1" onClick={pressCovidQAButton}>
                    <a href="/faq?tab=1" tabIndex={-1}>
                      <Trans i18nKey="covidFaq.covid19">COVID-19</Trans>
                      <img
                        className={QandA.arrowRight}
                        src="/imgs/internal-arrow.svg"
                        alt=""
                      />
                    </a>
                  </Link>
                </div>

                <Link to="/faq?tab=1" onClick={pressCovidQAButton}>
                  <div
                    className={QandA.cardContainer}
                    style={{ borderTop: '10px solid #0B86BD' }}
                  >
                    <button
                      className={QandA.cardTitleButton}
                      type="button"
                      style={{
                        backgroundColor: '#0B86BD',
                        fontSize:
                          props.displayLocale === 'es_US' ? '14px' : '16px',
                      }}
                    >
                      <Trans i18nKey="covidFaq.covid19">COVID-19</Trans>
                    </button>
                    <div className={QandA.cardText}>
                      <p>
                        <Trans i18nKey="covidFaq.covidQuestion1">
                          If I get an updated or additional dose of COVID-19,
                          will it show on my COVID-19 Vaccine Record?
                        </Trans>
                      </p>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <a className={QandA.learnMoreText} href="/faq?tab=1">
                          <Trans i18nKey="landingPage.learnMore">
                            Learn more
                          </Trans>
                          <img
                            className={QandA.arrowRightSmall}
                            src="/imgs/internal-arrow.svg"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className={QandA.columnContainer}>
                <div className={QandA.vaccineTitles}>
                  <Link
                    to="/faq?tab=2"
                    onClick={pressHealthcareProviderQAButton}
                  >
                    <a href="/faq?tab=2" tabIndex={-1}>
                      <Trans i18nKey="healthcareFaq.healthcareProviders">
                        Healthcare Providers
                      </Trans>
                      <img
                        className={QandA.arrowRight}
                        src="/imgs/internal-arrow.svg"
                        alt=""
                      />
                    </a>
                  </Link>
                </div>

                <Link to="/faq?tab=2" onClick={pressHealthcareProviderQAButton}>
                  <div
                    className={QandA.cardContainer}
                    style={{ borderTop: '10px solid #22499C' }}
                  >
                    <button
                      className={QandA.cardTitleButton}
                      type="button"
                      style={{
                        backgroundColor: '#22499C',
                        fontSize:
                          props.displayLocale === 'es_US' ? '14px' : '16px',
                      }} /* dangerouslySetInnerHTML={{ __html: fluButton }} */
                    >
                      <Trans i18nKey="healthcareFaq.healthcareProviders">
                        Healthcare Providers
                      </Trans>
                    </button>
                    <div className={QandA.cardText}>
                      {/* <div dangerouslySetInnerHTML={{ __html: fluText }} /> */}
                      <p>
                        <Trans i18nKey="healthcareFaq.healthcareQuestion1">
                          How do patients access their Digital Vaccine Record?
                        </Trans>
                      </p>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <a className={QandA.learnMoreText} href="/faq?tab=2">
                          <Trans i18nKey="landingPage.learnMore">
                            Learn more
                          </Trans>
                          <img
                            className={QandA.arrowRightSmall}
                            src="/imgs/internal-arrow.svg"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className={QandA.bottomTextContainer}>
              <p>
                <Trans i18nKey="landingPage.contactBody1">
                  Need help or have other questions? Call the CAIR help desk: 1-800-578-7889 
                  M-F 8AM-5PM (excludes holidays) NOTE:If you
                  need help locating your DVR, submit a request through
                  the Virtual Assistant. We're unable to locate your DVR over
                  the phone.
                </Trans>
              </p>
              <p>
                <Trans i18nKey="landingPage.contactBody2">
                  {/*
                                    NOTE:If you need help locating your DVR, submit a request through the Virtual Assistant. We're unable to locate your DVR over the phone. */}
                  <a
                    href={props.settings.VATLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: '#0d6efd',
                      margin: '0',
                      textDecoration: 'underline',
                    }}
                  >
                    Virtual Assistant
                  </a>
                </Trans>
              </p>
              {/* <div dangerouslySetInnerHTML={{ __html: bottomText }} /> */}
              {/* <Link 
                                href={{
                                    pathname: `/${props.displayLocale}/Questions-and-answers`,
                                    query: { name: 'question', tab: 0 },
                                }}>
                            </Link> */}
            </div>
          </div>
        </div>
      )}
      {isMobile === 'mobile' && (
        <div className={QandA.container}>
          <div className={QandA.titlesContainer}>
            <div className={QandA.title}>
              <Trans i18nKey="generalFaq.title">DVR Q&A</Trans>
            </div>
          </div>
          <div className={QandA.titleLinkContainerMobile}>
            <div className={QandA.vaccineTitlesContainerMobile}>
              <div className={QandA.vaccineTitles}>
                <Link to="/faq?tab=0">
                  <a href="/faq?tab=0">
                    <Trans i18nKey="generalFaq.general">General</Trans>
                    <img
                      className={QandA.arrowRight}
                      src="/imgs/internal-arrow.svg"
                      alt=""
                    />
                  </a>
                </Link>
              </div>
              <div className={QandA.vaccineTitles}>
                <Link to="/faq?tab=1">
                  <a href="/faq?tab=1">
                    <Trans i18nKey="covidFaq.covid19">COVID-19</Trans>
                    <img
                      className={QandA.arrowRight}
                      src="/imgs/internal-arrow.svg"
                      alt=""
                    />
                  </a>
                </Link>
              </div>

              <div className={QandA.vaccineTitles}>
                <Link to="/faq?tab=2">
                  <a href="/faq?tab=2">
                    <Trans i18nKey="healthcareFaq.healthcareProviders">
                      Healthcare Providers
                    </Trans>
                    <img
                      className={QandA.arrowRight}
                      src="/imgs/internal-arrow.svg"
                      alt=""
                    />
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <div className={QandA.cardContainerMobile}>
            <Link to="/faq?tab=0" onClick={pressGeneralQAButton}>
              <div className={QandA.singleCardMobile}>
                <div
                  className={QandA.cardHeaderMobile}
                  style={{ backgroundColor: '#F06724' }}
                >
                  <Trans i18nKey="generalFaq.general">General</Trans>
                </div>
                <div className={QandA.cardTextContainerMobile}>
                  <div className={QandA.cardTextMobile}>
                    <Trans i18nKey="generalFaq.generalQuestion1">
                      What is a Digital Vaccine Record?
                    </Trans>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <a className={QandA.learnMoreText} href="/faq?tab=0">
                      <Trans i18nKey="landingPage.learnMore">Learn more</Trans>
                      <img
                        className={QandA.arrowRightSmall}
                        src="/imgs/internal-arrow.svg"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="/faq?tab=1" onClick={pressCovidQAButton}>
              <div className={QandA.singleCardMobile}>
                <div
                  className={QandA.cardHeaderMobile}
                  style={{ backgroundColor: '#0B86BD' }}
                >
                  <Trans i18nKey="covidFaq.covid19">COVID-19</Trans>
                </div>
                <div className={QandA.cardTextContainerMobile}>
                  <div className={QandA.cardTextMobile}>
                    <Trans i18nKey="covidFaq.covidQuestion1">
                      If I get an updated or additional dose of COVID-19, will
                      it show on my COVID-19 Vaccine Record?
                    </Trans>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <a className={QandA.learnMoreText} href="/faq?tab=1">
                      <Trans i18nKey="landingPage.learnMore">Learn more</Trans>
                      <img
                        className={QandA.arrowRightSmall}
                        src="/imgs/internal-arrow.svg"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="/faq?tab=2" onClick={pressHealthcareProviderQAButton}>
              <div className={QandA.singleCardMobile}>
                <div
                  className={QandA.cardHeaderMobile}
                  style={{ backgroundColor: '#22499C' }}
                >
                  <Trans i18nKey="healthcareFaq.healthcareProviders">
                    Healthcare Providers
                  </Trans>
                </div>
                <div className={QandA.cardTextContainerMobile}>
                  <div className={QandA.cardTextMobile}>
                    <Trans i18nKey="healthcareFaq.healthcareQuestion1">
                      How do patients access their Digital Vaccine Record?
                    </Trans>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <a className={QandA.learnMoreText} href="/faq?tab=2">
                      <Trans i18nKey="landingPage.learnMore">Learn more</Trans>
                      <img
                        className={QandA.arrowRightSmall}
                        src="/imgs/internal-arrow.svg"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className={QandA.bottomTextContainer}>
            <div className={QandA.bottomText}>
              <p>
                <Trans i18nKey="landingPage.contactBody1">
                  Need help or have other questions? Call the CAIR help desk:
                  1-800-578-7889 M-F 8AM-5PM (excludes holidays)
                </Trans>
              </p>
              <p>
                <Trans i18nKey="landingPage.contactBody2">
                  {/* 
                                    NOTE:If you need help locating your DVR, submit a request through the Virtual Assistant. We're unable to locate your DVR over the phone. */}
                  <a
                    href={props.settings.VATLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: '#0d6efd',
                      margin: '0',
                      textDecoration: 'underline',
                    }}
                  >
                    Virtual Assistant
                  </a>
                </Trans>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default QAContent;
