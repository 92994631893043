import React from 'react';
import ReactGA from 'react-ga';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

function AppleHealthButton(props) {
  const appInsights = useAppInsightsContext();

  const pressAppleHealth = () => {
    appInsights.trackEvent({ name: 'Apple Health Button Pressed' });
  };

  return (
    <div data-html2canvas-ignore="true">
      {ReactGA.event({
        category: 'apple_render',
        action: 'Rendered Apple Health Button',
      })}

{/* temporary fix, if wallet content is a string and not an array, 
use wallet content and not walletcontent[0]*/}
      {typeof props.user?.walletContent === 'string' ? (

      <ReactGA.OutboundLink
        onClick={pressAppleHealth}
        eventLabel="apple_button"
        to={`${props.user?.walletContent}`}
        style={{ margin: '5px 0px 0px 0px' }}
      >
        <img
          id="apple-health-button"
          src={props.imgSource}
          alt={props.altText}
        />
      </ReactGA.OutboundLink>)
      : 
      (
      <ReactGA.OutboundLink
      onClick={pressAppleHealth}
      eventLabel="apple_button"
      to={`${props.user?.walletContent[0]}`}
      style={{ margin: '5px 0px 0px 0px' }}
      >
      <img
        id="apple-health-button"
        src={props.imgSource}
        alt={props.altText}
      />
      </ReactGA.OutboundLink>
)}


    </div>
  );
}

export default AppleHealthButton;
