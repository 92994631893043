import React, { useState, useEffect, createContext, useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import { useTranslation } from 'react-i18next';
import { create } from 'jss';
import rtl from 'jss-rtl';
import {
  StylesProvider,
  jssPreset,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js';
import Dashboard from './components/LandingPage';
import Header from './components/Header';
import Footer from './components/Footer';
import QrScreen from './components/QrScreen';
import Banner from './components/Banner';
import ReceivedScreen from './components/ReceivedScreen';
import InvalidPage from './components/InvalidPage';

// import i18n from './i18n';
import './styles/CovidCard-style.css';
import './styles/override/styles.scss';
// eslint-disable-next-line no-unused-vars
// import i18n from './i18n';

import { reactPlugin } from './utils/ApplicationInsightsService';
import RequestForm from './components/RequestForm';
import FaqPage from './components/FaqPage';
import SearchPage from './components/SearchPage';
import MyTurnBanner from './components/MyTurnBanner';

const { CREDENTIALS_GA_DEPARTMENT } = window.config;

const { APP_SETTINGS_URL } = window.config;

ReactGA.initialize(CREDENTIALS_GA_DEPARTMENT);

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function App() {
  useEffect(() => {
    RetrieveAppSettings();
  }, []);

  const [settings, setSettings] = useState({
    ShowMaintenanceBanner: false,
    MaintenanceBannerClosed: true,
    VATLink: 'https://chat.myvaccinerecord.cdph.ca.gov',
    MyTurnBannerStatus: false,
    MyTurnLink: 'https://myturn.ca.gov/',
    MyTurnBannerVaccineGroups: {},
  });

  const [myTurnBannerGroupCodes, setMyTurnBannerGroupCodes] = useState([]);

  async function RetrieveAppSettings() {
    try {
      const response = await fetch(APP_SETTINGS_URL, {
        headers: {
          'Cache-Control': 'no-cache',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch settings');
      }

      const jsonData = await response.json();

      setSettings({ ...jsonData, MaintenanceBannerClosed: !jsonData.ShowMaintenanceBanner });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const [showDVRHeader, setShowDVRHeader] = React.useState(true);
  const [htmlDir, setHtmlDir] = useState(
    window.localStorage.getItem('doc dir'),
  );

  // const isRTL = () => document.documentElement.dir === 'rtl';

  const ThemeContext = createContext();
  // eslint-disable-next-line no-unused-vars
  const useThemeContext = () => useContext(ThemeContext);

  // Do not remove; this will break translation
  const { i18n } = useTranslation();

  document.body.dir =
    i18n.language === 'ae' || i18n.language === 'ar' ? 'rtl' : 'ltr';
  const theme = createMuiTheme({
    direction: htmlDir,
  });

  return (
    <AppInsightsErrorBoundary
      onError={() => <h1>I believe something went wrong</h1>}
      appInsights={reactPlugin}
    >
      <StylesProvider jss={jss}>
        {/* <ThemeContext.Provider value={{ htmlDir }}> */}
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <div className="App">
              <Router>
                {showDVRHeader && (
                  <Header setHtmlDir={setHtmlDir} htmlDir={htmlDir} />
                )}
                <Banner settings={settings} setSettings={setSettings} />
                <AppInsightsContext.Provider value={reactPlugin}>
                  <Route exact path="/">
                    <Dashboard htmlDir={htmlDir} settings={settings} />
                  </Route>
                </AppInsightsContext.Provider>
                <Route exact path="/request">
                  <RequestForm htmlDir={htmlDir} />
                </Route>
                <Route exact path="/faq">
                  <FaqPage htmlDir={htmlDir} settings={settings} />
                </Route>
                <Route exact path="/search">
                  <SearchPage htmlDir={htmlDir} settings={settings} />
                </Route>
                <AppInsightsContext.Provider value={reactPlugin}>
                  <Route path="/received" component={ReceivedScreen} />
                </AppInsightsContext.Provider>
                <AppInsightsContext.Provider value={reactPlugin}>
                  <Switch>
                    <Route path="/qr/:lang/:requestType/:id">
                      <MyTurnBanner
                        settings={settings}
                        myTurnBannerGroupCodes={myTurnBannerGroupCodes}
                      />
                      <QrScreen
                        htmlDir={htmlDir}
                        setHtmlDir={setHtmlDir}
                        setShowDVRHeader={setShowDVRHeader}
                        settings={settings}
                        myTurnBannerGroupCodes={myTurnBannerGroupCodes}
                        setMyTurnBannerGroupCodes={setMyTurnBannerGroupCodes}
                      />
                    </Route>
                    <Route path="/qr/:lang/:id">
                      <InvalidPage
                        htmlDir={htmlDir}
                        setHtmlDir={setHtmlDir}
                        setShowDVRHeader={setShowDVRHeader}
                      />
                    </Route>
                  </Switch>
                </AppInsightsContext.Provider>
              </Router>
              <AppInsightsContext.Provider value={reactPlugin}>
                <Footer settings={settings} />
              </AppInsightsContext.Provider>
            </div>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
        {/* </ThemeContext.Provider> */}
      </StylesProvider>
    </AppInsightsErrorBoundary>
  );
}

export default App;
