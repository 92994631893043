import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
  Link,
} from '@react-pdf/renderer';
/* eslint-disable react/display-name */

Font.register({
  family: 'Public Sans',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/publicsans/v14/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuSJm5ww0pX189fg.ttf',
    }, // font-style: normal, font-weight: normal
    {
      src: 'https://fonts.gstatic.com/s/publicsans/v14/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673toPgActfVotfj7j.ttf',
      fontStyle: 'italic',
    },
    {
      src: 'https://fonts.gstatic.com/s/publicsans/v14/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuJJm5ww0pX189fg.ttf',
      fontStyle: 'bold',
    },
    {
      src: 'https://fonts.gstatic.com/s/publicsans/v14/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymu8Z65ww0pX189fg.ttf',
      fontWeight: '700',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: '0.4in 0.4in 0.6in 0.4in',
  },
  titleStyle: {
    color: '#22489C',
    fontSize: '22px',
    fontWeight: 'bold',
    paddingBottom: 5,
    fontFamily: 'Public Sans',
    // eslint-disable-next-line no-dupe-keys
    fontWeight: '700',
  },
  headerStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 15,
  },
  headerTextStyle: {
    fontSize: '10px',
    fontFamily: 'Public Sans',
  },
  tableStyle: {
    display: 'flex',
    width: 'auto',
  },
  groupHeaderStyle: {
    paddingBottom: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  groupHeaderText: {
    fontSize: '13px',
    paddingRight: 3,
    fontFamily: 'Public Sans',
    fontStyle: 'bold',
  },
  tableHeader: {
    flexDirection: 'row',
    borderRight: '1 solid gray',
    borderTop: '1 solid gray',
    borderBottom: '1 solid gray',
    backgroundColor: '#E1E0E3',
    fontFamily: 'Helvetica-Bold',
  },
  groupTable: {
    paddingBottom: 20,
  },
  tableHeaderCellLarge: {
    padding: '8 10',
    borderLeft: '1 solid gray',
    width: '27.5%',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
  },
  tableHeaderCellSmall: {
    padding: '8 10',
    borderLeft: '1 solid gray',
    width: '15%',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
  },
  tableHeaderText: {
    fontSize: '9px',
    fontWeight: 'bold',
  },
  tableBodyRowStyle: {
    flexDirection: 'row',
    borderBottom: '1 solid gray',
    borderRight: '1 solid gray',
  },
  tableCellLarge: {
    padding: 10,
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    borderLeft: '1 solid gray',
    width: '27.5%',
  },
  tableHeaderRecommendation: {
    flexDirection: 'row',
    borderRight: '1 solid gray',
    borderTop: '1 solid gray',
    borderBottom: '1 solid gray',
    backgroundColor: '#E1E0E3',
    fontFamily: 'Helvetica-Bold',
    width: '80%',
  },
  tableHeaderCellRecommendation: {
    padding: '8 10',
    borderLeft: '1 solid gray',
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
  },
  tableBodyRowStyleRecommendation: {
    flexDirection: 'row',
    borderBottom: '1 solid gray',
    borderRight: '1 solid gray',
    width: '80%',
  },
  tableCellRecommendation: {
    padding: 10,
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    borderLeft: '1 solid gray',
    width: '50%',
  },
  tableCellRecommendationLeft: {
    justifyContent: 'left',
    borderLeft: '1 solid gray',
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '6%',
    paddingRight: '6%',
  },
  tableCellTextRecommendation: {
    fontSize: '9px',
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '4px',
  },
  tableCellSmall: {
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    padding: 10,
    borderLeft: '1 solid gray',
    // eslint-disable-next-line no-dupe-keys
    textAlign: 'center',
    width: '15%',
  },
  tableCellText: {
    fontSize: '9px',
  },
  recommendedHeaderStyle: {
    paddingBottom: 5,
  },
  recommendedHeaderText: {
    paddingTop: 15,
    fontSize: '15px',
    paddingBottom: 10,
    fontFamily: 'Public Sans',
    fontStyle: 'bold',
  },
  recommendedVaccineText: {
    fontSize: '13px',
    fontFamily: 'Public Sans',
    paddingLeft: 5,
    fontStyle: 'bold',
  },
  definitionStyle: {
    paddingTop: 15,
  },
  definitionSection: {
    paddingBottom: 15,
    fontSize: '12px',
    fontFamily: 'Public Sans',
  },
  footerStyle: {
    position: 'absolute',
    bottom: '0.3in',
    left: '0.4in',
    right: '0.4in',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 15,
  },
  imageLeftStyle: {
    height: '30px',
    width: 'auto',
  },
  imageRightStyle: {
    height: '30px',
    width: 'auto',
  },
  pageNumber: {
    position: 'absolute',
    bottom: '0.4in',
    left: '0.4in',
    right: '0.4in',
    textAlign: 'center',
    fontSize: '8px',
    fontFamily: 'Public Sans',
  },
  line: {
    border: '0.5px solid gray',
  },
  circleGreen: {
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    backgroundColor: '#009227',
  },
  circleYellow: {
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    border: '1px solid black',
    backgroundColor: '#FDB81E',
  },
  circleRed: {
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    backgroundColor: '#D30F00',
  },
  circleLightBlue: {
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    border: '1px solid black',
    backgroundColor: '#A3D8F4',
  },
});

function displayForecast(group) {
  let status =
    group.nextDose === null ? 'NOT_RECOMMENDED' : group.nextDose.status;
  try {
    if (group.nextDose.isPatientDiscretion === 'true') {
      status = 'CLINICAL_PATIENT_DISCRETION';
    }
  } catch {}
  let circleColor = 'black';
  const dueNow = group.nextDose == null ? false : group.nextDose.dueNow;
  // eslint-disable-next-line default-case
  switch (status) {
    case 'RECOMMENDED':
    case 'FUTURE_RECOMMENDED':
      circleColor = dueNow ? '#D30F00' : '#FDB81E';
      break;

    // complete
    case 'CONDITIONAL':
    case 'NOT_RECOMMENDED':
      circleColor = '#009227';
      break;

    case 'CLINICAL_PATIENT_DISCRETION':
      circleColor = '#A3D8F4';
      break;

    case 'RECOMMENDATION_NOT_AVAILABLE':
      break;
  }

  if (circleColor === '#009227') {
    return <Text style={styles.circleGreen} />;
  }
  if (circleColor === '#D30F00') {
    return <Text style={styles.circleRed} />;
  }
  if (circleColor === '#FDB81E') {
    return <Text style={styles.circleYellow} />;
  }
  if (circleColor === '#A3D8F4') {
    return <Text style={styles.circleLightBlue} />;
  }
}

function isInvalidSeries(dose, evaluatorAvailable) {
  if (evaluatorAvailable && dose.series === 'Invalid') {
    return (
      <Text>
        {dose.series}
        {dose.invalidSuperscriptLabel && (
          <Text style={{ verticalAlign: 'super', fontSize: 5 }}>
            1{dose.invalidSuperscriptLabel}
          </Text>
        )}
      </Text>
    );
  }

  return <Text>{dose.series}</Text>;
}

function createTable(doses, evaluatorAvailable) {
  return (
    <View>
      {/* table header */}
      <View style={styles.tableHeader} fixed>
        <View style={styles.tableHeaderCellLarge}>
          <Text style={styles.tableHeaderText}>Vaccine</Text>
        </View>
        <View style={styles.tableHeaderCellSmall}>
          <Text style={styles.tableHeaderText}>
            {evaluatorAvailable ? 'Dose' : 'Series'}
          </Text>
        </View>
        <View style={styles.tableHeaderCellSmall}>
          <Text style={styles.tableHeaderText}>Date Given</Text>
        </View>
        <View style={styles.tableHeaderCellSmall}>
          <Text style={styles.tableHeaderText}>Age Given</Text>
        </View>
        <View style={styles.tableHeaderCellLarge}>
          <Text style={styles.tableHeaderText}>
            Clinic that Administered or Transcribed
          </Text>
        </View>
      </View>

      {/* table data */}
      <View>
        {doses.map((dose) => (
          <View style={styles.tableBodyRowStyle} wrap={false}>
            <View style={styles.tableCellLarge}>
              <Text style={styles.tableCellText}>
                {dose.vaccineName === '' ? ' ' : dose.vaccineName}
              </Text>
            </View>
            <View style={styles.tableCellSmall}>
              <Text style={styles.tableCellText}>
                {dose.series === '' ? (
                  ' '
                ) : isNA(dose.series) ? (
                  <Text>
                    {dose.series}
                    <Text style={{ verticalAlign: 'super', fontSize: 5 }}>
                      2
                    </Text>
                  </Text>
                ) : (
                  isInvalidSeries(dose, evaluatorAvailable)
                )}
              </Text>
            </View>
            <View style={styles.tableCellSmall}>
              <Text style={styles.tableCellText}>
                {dose.dateGiven === '' ? ' ' : dose.dateGiven}
              </Text>
            </View>
            <View style={styles.tableCellSmall}>
              <Text style={styles.tableCellText}>
                {dose.ageGiven === '' ? ' ' : dose.ageGiven}
              </Text>
            </View>
            <View style={styles.tableCellLarge}>
              <Text style={styles.tableCellText}>
                {dose.clinicName === '' ? ' ' : dose.clinicName}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
}

function createRecommendationTable(recommendedGroups, displayVaccineGroupName) {
  return (
    <View>
      {/* table header */}
      <View style={styles.tableHeaderRecommendation}>
        <View style={styles.tableHeaderCellRecommendation}>
          <Text style={styles.tableHeaderText}>Vaccine Group</Text>
        </View>
        <View style={styles.tableHeaderCellRecommendation}>
          <Text style={styles.tableHeaderText}>Recommended Date</Text>
        </View>
      </View>

      {/* table data */}
      <View>
        {recommendedGroups.map((group) => {
          let { recommendedDate } = group.nextDose;
          if (group.nextDose.isPatientDiscretion === 'true') {
            recommendedDate = 'Consult your healthcare provider';
          }

          return (
            <View wrap={false} style={styles.tableBodyRowStyleRecommendation}>
              <View style={styles.tableCellRecommendationLeft}>
                {displayForecast(group)}
                <Text style={styles.tableCellTextRecommendation}>
                  {displayVaccineGroupName(group)}
                </Text>
              </View>
              <View style={styles.tableCellRecommendation}>
                <Text style={styles.tableCellText}>{recommendedDate}</Text>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
}

function isNA(value) {
  return value === 'N/A';
}

export const PDFFile = React.forwardRef(({ ...props }, _ref) => (
  <Document>
    <Page size="A4" orientation="portrait" style={styles.page} wrap>
      {/* title */}
      <View>
        <Text style={styles.titleStyle}>California Immunization Record</Text>
      </View>

      {/* name, dob, record issued on header */}
      <View style={styles.headerStyle} fixed>
        <Text style={styles.headerTextStyle}>
          <Text style={{ fontStyle: 'bold' }}>Name: </Text>{' '}
          {props.record.firstName} {props.record.lastName}
        </Text>
        <Text style={styles.headerTextStyle}>
          <Text style={{ fontStyle: 'bold' }}>Date of Birth: </Text>{' '}
          {props.record.dob}
        </Text>
        <Text style={styles.headerTextStyle}>
          <Text style={{ fontStyle: 'bold' }}>Record Issued On: </Text>{' '}
          {props.record.currentDate}
        </Text>
      </View>

      {/* create vaccine group */}
      <View style={styles.tableStyle}>
        {props.record != null &&
          props.record.vaccineGroups != null &&
          props.record.vaccineGroups.map((group) => (
            <View
              style={styles.groupTable}
              wrap={group.existingDoses.length > 10}
            >
              {/* display vaccine group name and forecast status */}
              <View style={styles.groupHeaderStyle}>
                <Text style={styles.groupHeaderText}>
                  {props.displayVaccineGroupName(group)}
                </Text>
                {props.record.evaluatorAvailable && group.displayForecast && (
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Text style={styles.groupHeaderText}>
                      {props.displayEvaluatorStatus(group.nextDose)}
                    </Text>
                    {displayForecast(group)}
                  </View>
                )}
              </View>
              {/* create the table */}
              {createTable(
                group.existingDoses,
                props.record.evaluatorAvailable,
              )}
            </View>
          ))}
      </View>

      {/* recommended vaccines */}
      {props.record &&
        props.record.evaluatorAvailable &&
        props.record.emptyGroups.length > 0 && (
          <View style={styles.recommendedHeaderStyle} wrap={false}>
            <View style={{ border: '0.5px solid gray' }} />
            <Text style={styles.recommendedHeaderText}>
              Recommended vaccinations we do not see in your record (future
              recommendations shown in yellow are due within one year):
            </Text>
            {props.record != null &&
              props.record.emptyGroups != null &&
              createRecommendationTable(
                props.record.emptyGroups,
                props.displayVaccineGroupName,
              )}
          </View>
        )}
      {props.record.evaluatorAvailable && (
        <View style={styles.definitionStyle} wrap={false}>
          <Text
            style={{
              fontSize: '11px',
              fontFamily: 'Public Sans',
              paddingBottom: 15,
            }}
          >
            <Text
              style={{
                backgroundColor: '#22489c',
                color: '#ffffff',
              }}
            >
              Note:
            </Text>
            <Text
              style={{
                color: '#ffffff',
              }}
            >
              d
            </Text>
            The recommendations displayed on your DVR are based on guidelines
            from the Centers for Disease Control and Prevention (CDC). Your
            healthcare provider may make different vaccine recommendations based
            on your individual risk factors.
          </Text>
          <Text
            style={{
              fontSize: '11px',
              fontFamily: 'Public Sans',
              paddingBottom: 15,
            }}
          >
            Visit the CDC website to view the most recent{' '}
            <Link src="https://www.cdc.gov/vaccines/schedules/index.html">
              recommended vaccination schedules.
            </Link>
          </Text>
        </View>
      )}

      <View wrap={false}>
        <hr style={styles.line} />
      </View>

      {/* definition footer */}
      <View style={styles.definitionStyle}>
        <View wrap={false}>
          <Text
            style={{
              fontSize: '11px',
              fontFamily: 'Public Sans',
              paddingBottom: 15,
            }}
          >
            NOTE: It is possible that some or all vaccine doses you received
            were not reported to the California Immunization Registry (CAIR),
            and therefore your Digital Vaccine Record may not be complete. If
            you have questions about your vaccination record,{' '}
            <Link src="https://myvaccinerecord.cdph.ca.gov/faq">
              visit our FAQ.
            </Link>
          </Text>
        </View>

        {props.record.evaluatorAvailable && (
          <View wrap={false} style={styles.definitionSection}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: 5,
                alignItems: 'center',
              }}
            >
              <Text style={styles.circleGreen} />
              <Text style={{ paddingLeft: 5 }}>
                Symbolizes that based on CDC recommendations the vaccine group
                is up to date.
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: 5,
                alignItems: 'center',
              }}
            >
              <Text style={styles.circleYellow} />
              <Text style={{ paddingLeft: 5 }}>
                Symbolizes that the next dose for the vaccine group is upcoming
                and medically recommended.
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: 5,
                alignItems: 'center',
              }}
            >
              <Text style={styles.circleRed} />
              <Text style={{ paddingLeft: 5 }}>
                Symbolizes that the next dose for the vaccine group is overdue
                and medically recommended.
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Text style={styles.circleLightBlue} />
              <Text style={{ paddingLeft: 5 }}>
                Symbolizes to consult your healthcare provider.
              </Text>
            </View>
          </View>
        )}

        <View wrap={false} style={styles.definitionSection}>
          <Text style={{ paddingBottom: 5 }}>
            1. <Text style={{ fontStyle: 'italic' }}>Invalid</Text> means that a
            dose was not given according to the medical schedule. But, the dose
            may still meet school requirements.
          </Text>
          {props.record.invalidDoseReasons &&
            props.record.evaluatorAvailable &&
            props.record.invalidDoseReasons.map((reason) => (
              <View wrap={false}>
                <Text style={{ paddingLeft: 20, paddingBottom: 5 }}>
                  {reason.invalidSuperscriptLabel}. {reason.invalidReason}
                </Text>
              </View>
            ))}
          <br />
          <Text style={{ paddingBottom: 5 }}>
            2. <Text style={{ fontStyle: 'italic' }}>N/A</Text> means that the
            dose has missing information in the registry and was excluded from
            vaccine forecasting.
          </Text>
          <Text style={{ paddingBottom: 5 }}>
            3. <Text style={{ fontStyle: 'italic' }}>Complete or Aged out</Text>{' '}
            means that a dose is not medically needed.
          </Text>
          <br />
          <Text style={{ paddingBottom: 5 }}>
            4. <Text style={{ fontStyle: 'italic' }}>Transcribed by</Text>{' '}
            indicates that the clinic entered the information to CAIR but did
            not administer that dose.
          </Text>
          <br />
          <Text style={{ paddingBottom: 5 }}>
            5. <Text style={{ fontStyle: 'italic' }}>Combination vaccines</Text>{' '}
            are listed multiple times - once for each disease they protect
            against.
          </Text>
          <br />
          <Text>
            6. <Text style={{ fontStyle: 'italic' }}>Extra Dose</Text> means
            that the immunization was administered as an extra dose.
          </Text>
        </View>

        <View wrap={false} style={styles.definitionSection}>
          <Text style={{ paddingBottom: 3 }}>Definitions:</Text>
          <Text style={{ paddingBottom: 3, paddingLeft: 10 }}>
            COVID-19: <Text style={{ fontStyle: 'italic' }}>Coronavirus</Text>
          </Text>
          <Text style={{ paddingBottom: 3, paddingLeft: 10 }}>
            DT, Td:{' '}
            <Text style={{ fontStyle: 'italic' }}>diphtheria, tetanus</Text>
          </Text>
          <Text style={{ paddingBottom: 3, paddingLeft: 10 }}>
            DTaP, Tdap:{' '}
            <Text style={{ fontStyle: 'italic' }}>
              diphtheria, tetanus, pertussis (whooping cough)
            </Text>
          </Text>
          <Text style={{ paddingBottom: 3, paddingLeft: 10 }}>
            Flu:{' '}
            <Text style={{ fontStyle: 'italic' }}>
              influenza (only the most recent 3 doses are listed)
            </Text>
          </Text>
          <Text style={{ paddingBottom: 3, paddingLeft: 10 }}>
            HepA: <Text style={{ fontStyle: 'italic' }}>hepatitis A</Text>
          </Text>
          <Text style={{ paddingBottom: 3, paddingLeft: 10 }}>
            HepB: <Text style={{ fontStyle: 'italic' }}>hepatitis B</Text>
          </Text>
          <Text style={{ paddingBottom: 3, paddingLeft: 10 }}>
            Hib:{' '}
            <Text style={{ fontStyle: 'italic' }}>
              haemophilus influenza B (hib meningitis)
            </Text>
          </Text>
          <Text style={{ paddingBottom: 3, paddingLeft: 10 }}>
            HPV:{' '}
            <Text style={{ fontStyle: 'italic' }}>human papillomavirus</Text>
          </Text>
          <Text style={{ paddingBottom: 3, paddingLeft: 10 }}>
            HZV, RZV, ZVL:{' '}
            <Text style={{ fontStyle: 'italic' }}>
              herpes zoster (shingles)
            </Text>
          </Text>
          <Text style={{ paddingBottom: 3, paddingLeft: 10 }}>
            IPV, OPV: <Text style={{ fontStyle: 'italic' }}>polio</Text>
          </Text>
          <Text style={{ paddingBottom: 3, paddingLeft: 10 }}>
            MenACWY (MCV4), MenB, MPSV:{' '}
            <Text style={{ fontStyle: 'italic' }}>meningococcal</Text>
          </Text>
          <Text style={{ paddingBottom: 3, paddingLeft: 10 }}>
            MMR:{' '}
            <Text style={{ fontStyle: 'italic' }}>measles, mumps, rubella</Text>
          </Text>
          <Text style={{ paddingBottom: 3, paddingLeft: 10 }}>
            PCV, PPSV:{' '}
            <Text style={{ fontStyle: 'italic' }}>
              pneumococcal (pneumonia)
            </Text>
          </Text>
          <Text style={{ paddingBottom: 3, paddingLeft: 10 }}>
            Rota, RV: <Text style={{ fontStyle: 'italic' }}>rotavirus</Text>
          </Text>
          <Text style={{ paddingBottom: 3, paddingLeft: 10 }}>
            S-pox: <Text style={{ fontStyle: 'italic' }}>Smallpox</Text>
          </Text>
          <Text style={{ paddingBottom: 3, paddingLeft: 10 }}>
            Td: <Text style={{ fontStyle: 'italic' }}>tetanus</Text>
          </Text>
          <Text style={{ paddingBottom: 3, paddingLeft: 10 }}>
            VAR:{' '}
            <Text style={{ fontStyle: 'italic' }}>varicella (chickenpox)</Text>
          </Text>
          <Text style={{ paddingLeft: 10 }}>
            YF: <Text style={{ fontStyle: 'italic' }}>Yellow Fever</Text>
          </Text>
        </View>
      </View>
      {/* page numbers */}
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) =>
          `Page ${pageNumber} of ${totalPages}`
        }
        fixed
      />

      {/* official logos */}
      <View style={styles.footerStyle} fixed>
        <Image
          style={styles.imageLeftStyle}
          src="/imgs/my-dvr-logo.png"
        />

        <Image style={styles.imageRightStyle} src="/imgs/new-cdph-logo.png" />
      </View>
    </Page>
  </Document>
));
export default PDFFile;
