import { TextField, IconButton } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Search from '@material-ui/icons/Search'
import { useHistory } from 'react-router-dom';

function MobileSearchBar(props) {
    const history = useHistory();

    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const query = searchParams.get('query');
        if (query != "null") {
          setSearchQuery(query);
        }
  }, []);

    const handleSubmit = (e) => {
        
        e.preventDefault();
        let url = '/search?query=' + searchQuery
        history.push(url)
        if(props.setSearchQuery) {
            props.setSearchQuery(searchQuery)
        }
    }

    

  return (
    <form className='MobileSearchBar' onSubmit={handleSubmit}>
        
        <TextField
        id="search-bar"
        className="text"
        variant="outlined"
        placeholder={'Search...'}
        value={searchQuery}
        size="small"
        style={{width:'100%'}}
        onChange={e=>setSearchQuery(e.target.value)}
        inputProps={{maxLength:200}}
        autoComplete='off'
        InputProps={{
          endAdornment: (
            <IconButton type="submit" aria-label="search" >
        <Search style={{ fill: "blue" }} />
        </IconButton>
          ),
        }}
        />
    </form>
  );
}
export default MobileSearchBar;
